.user-dob {
  text-align-last: center;
}

.user-dod {
  text-align-last: center;
  margin-top: 10px;
}

.timeline .timeline-date {
  height: 110px !important;
}

.year {
  font-size: 15px;
}
